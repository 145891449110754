AFRAME.registerComponent("radar", {
  schema: {
    min: { type: "number", default: 5 },
    max: { type: "number", default: 85 },
  },
  init: function () {
    const self = this;
    self.camera = document.querySelector("#camera");
    self.radar = document.querySelector("#radar");
    self.videoSphereEle = document.querySelector("a-videosphere");

    var sketch = function (p) {
      p.setup = function () {
        p.createCanvas(radar.clientWidth, radar.clientHeight);
      };
      p.draw = function () {
        const angle = self.radar.getAttribute("angle");
        const borderColor = p.color("rgb(255,255,255)");
        const fillColor = p.color("rgb(38, 119, 217)");
        borderColor.setAlpha(200);
        fillColor.setAlpha(150);
        const fov = p.PI * (self.fov / 180);
        p.clear();
        p.stroke(borderColor);
        p.strokeWeight(2);
        p.fill(fillColor);
        p.arc(
          100,
          100,
          180,
          180,
          self.rotationY - fov / 2 + parseFloat(angle),
          self.rotationY + fov / 2 + parseFloat(angle),
          p.PIE
        );
      };
    };
    this.p5 = new p5(sketch, "radar");
  },
  tick: function () {
    const rotation = this.camera.object3D.rotation;
    const fov = Number(this.camera.getAttribute("camera").fov);
    this.rotationX = rotation._x;
    this.rotationY = rotation._y;
    this.fov = fov;
  },
});
