let p5Instance;
let drawing = false;
let otherDrawing = false;
let socketId = null;
let drawer = null;
export function clearCanvas() {
  p5Instance.clear();
}

export function stopDrawing() {
  drawing = false;

  p5Instance.clear();
  document.querySelector(".show-canvas-button").classList.remove("hidden");
  document.querySelector(".hide-canvas-button").classList.add("hidden");
  document.querySelector("#canvas").classList.add("hidden");
}
export function initCanvas() {
  const socket = io.connect("/");
  const color = "yellow";
  const strokeWidth = 10;
  var sketch = function (p) {
    p.setup = function () {
      p.createCanvas(p.windowWidth, p.windowHeight);
      socket.on("mouse", (data) => {
        p.stroke(data.color);
        p.strokeWeight(data.strokeWidth);
        const ratioX = p.windowWidth / data.windowWidth;
        const ratioY = p.windowHeight / data.windowHeight;
        p.line(
          data.x * ratioX,
          data.y * ratioY,
          data.px * ratioX,
          data.py * ratioY
        );
      });
    };
    p.draw = function () {
      if (!drawing) {
        return;
      }
      p.stroke(color);
      p.strokeWeight(strokeWidth);
      if (p.mouseIsPressed === true) {
        p.line(p.mouseX, p.mouseY, p.pmouseX, p.pmouseY);
        socket.emit("mouse", {
          x: p.mouseX,
          y: p.mouseY,
          px: p.pmouseX,
          py: p.pmouseY,
          color,
          strokeWidth,
          windowWidth: p.windowWidth,
          windowHeight: p.windowHeight,
        });
      }
    };
  };
  p5Instance = new p5(sketch, "canvas");

  socket.on("id", function (data) {
    socketId = data;
  });
  socket.on("enter-drawing", function (data) {
    otherDrawing = true;
    // document.querySelector(".show-canvas-button").classList.add("hidden");
    // document.querySelector(".hide-canvas-button").classList.remove("hidden");
    document.querySelector("#canvas").classList.remove("hidden");
  });

  socket.on("quit-drawing", function () {
    p5Instance.clear();
    otherDrawing = false;
    drawing = false;
    document.querySelector(".show-canvas-button").classList.remove("hidden");
    document.querySelector(".hide-canvas-button").classList.add("hidden");
    document.querySelector("#canvas").classList.add("hidden");
    document.querySelector("#canvas").classList.remove("drawing");
  });
  document.querySelectorAll(".canvas-button").forEach((btn) => {
    btn.addEventListener("click", (event) => {
      drawing = !drawing;
      if (!otherDrawing) {
        p5Instance.clear();
        if (drawing) {
          document.querySelector("#canvas").classList.remove("hidden");
          socket.emit("enter-drawing");
        } else {
          document.querySelector("#canvas").classList.add("hidden");
          socket.emit("quit-drawing");
        }
      }
      document.querySelectorAll(".canvas-button").forEach((b) => {
        b.classList.toggle("hidden");
      });
      document.querySelector("#canvas").classList.toggle("drawing");
    });
  });
}

initCanvas();
