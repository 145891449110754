const _ = require("lodash");
const { stopDrawing } = require("./canvas");
let currentTarget = undefined;
const supabase = require("@supabase/supabase-js");
export const videoStatus = {};
let tour = null;
let scale = 0.4;
function loadVideo(target) {
  const video = _.find(tour.videos, (v) => v.id.toString() === target);
  const assets = document.querySelector("a-assets");
  const videoSphereEle = document.querySelector("a-videosphere");
  const skyEle = document.querySelector("a-sky");
  const playBtn = document.querySelector("#play-button");
  const pauseBtn = document.querySelector("#pause-button");
  const camera = document.querySelector("#camera");
  camera.setAttribute("rotation", { x: 0, y: 0, z: 0 });

  const angle = Math.PI * (video.angle / 180) + Math.PI / 2;

  const radar = document.querySelector("#radar");
  skyEle.setAttribute("src", `#image_${target}`);
  skyEle.object3D.visible = true;
  videoSphereEle.object3D.visible = false;
  videoSphereEle?.components?.material?.material?.map?.image?.pause();

  radar.setAttribute(
    "style",
    `position: absolute; left: ${video.positionX * scale}px; bottom: ${
      video.positionY * scale
    }px`
  );

  radar.setAttribute("angle", angle);

  document.querySelectorAll(".stepper__input").forEach((i) => {
    i.removeAttribute("checked");
  });
  document.querySelectorAll(".circle").forEach((i) => {
    i.classList.remove("checked");
  });
  document
    .querySelector(`.stepper__input[data-target='${target}']`)
    .setAttribute("checked", true);
  document
    .querySelector(`.circle[data-target='${target}']`)
    .classList.add("checked");

  if (target === tour.videos[0].id) {
    document.getElementById("prev-button")?.classList?.add("hidden");
  } else {
    document.getElementById("prev-button")?.classList?.remove("hidden");
  }

  if (target === tour.videos[tour.videos.length - 1].id) {
    document.getElementById("next-button")?.classList?.add("hidden");
  } else {
    document.getElementById("next-button")?.classList?.remove("hidden");
  }
  currentTarget = target;
  if (video.url) {
    const currentVideoElement = document.getElementById(`video_${video.id}`);
    if (!currentVideoElement) {
      const newVideoElement = document.createElement("video");
      newVideoElement.setAttribute("loop", "");
      newVideoElement.setAttribute("id", `video_${video.id}`);
      newVideoElement.setAttribute("data-target", `${video.id}`);
      assets.append(newVideoElement);
      const hls = new Hls({ maxBufferLength: 3 });
      hls.loadSource(video.url);
      hls.attachMedia(newVideoElement);
      hls.on(Hls.Events.ERROR, function (event, data) {
        console.log(event, data);
        const loading = document.querySelector("#loading");
        switch (data.details) {
          case Hls.ErrorDetails.BUFFER_STALLED_ERROR:
            if (video.id === currentTarget) {
              loading.classList.remove("hidden");
            }
            break;
          default:
            break;
        }
      });

      hls.on(Hls.Events.BUFFER_APPENDED, function (event, data) {
        const { media } = data;
        if (video.id === currentTarget) {
          loading.classList.add("hidden");
        }
      });
      playBtn.classList.add("hidden");
      pauseBtn.classList.add("hidden");
      newVideoElement.addEventListener("loadedmetadata", (event) => {
        videoStatus[video.id] ||= {};
        videoStatus[video.id].canplay = true;
        if (event.target.getAttribute("data-target") === currentTarget) {
          playBtn.classList.remove("hidden");
          pauseBtn.classList.add("hidden");
        }
      });
    } else {
      if (videoStatus[video.id]?.canplay) {
        playBtn.classList.remove("hidden");
        pauseBtn.classList.add("hidden");
      }
    }

    videoSphereEle.setAttribute("src", `#video_${target}`);
  } else {
    playBtn.classList.add("hidden");
    pauseBtn.classList.add("hidden");
  }
  videoSphereEle.object3D.visible = false;
  skyEle.object3D.visible = true;
  skyEle.setAttribute("src", `#image_${target}`);
  stopDrawing();
}
function buildNav() {
  const template = Handlebars.compile(`
    <input
      class="stepper__input"
      id="{{ target }}"
      data-target="{{target}}"
      name="stepper-flex"
      type="radio"
      {{checked}}
    />
    <div class="stepper__step">
      <label
        class="stepper__button"
        data-target="{{target}}"
        for="{{ target }}"
        >{{name}}</label
      >
    </div>
  `);

  const stepper = document.querySelector(".stepper");
  const navs = tour.videos.map((v, i) => {
    return template({
      target: v.id,
      name: v.title,
      checked: i === 0 ? "checked" : null,
    });
  });
  stepper.innerHTML = navs.join("");

  document.querySelectorAll(".stepper__button").forEach(function (step) {
    step.addEventListener("click", function (event) {
      const target = event.target.getAttribute("data-target");
      if (target === currentTarget) {
        return;
      }
      loadVideo(target);
    });
  });
}

function buildMiniMap(onFinish) {
  const container = document.querySelector(".minimap-inner");
  const minimap = document.querySelector(".minimap");
  const containerWidth = minimap.clientWidth;

  const img = new Image();
  img.src = tour.minimap_url;
  img.addEventListener("load", function () {
    const image = this;
    scale = containerWidth / image.width;

    minimap.style = `background-image: url(${tour.minimap_url}); height: ${
      image.height * scale
    }px`;

    tour.videos.forEach((v) => {
      const circle = document.createElement("div");
      circle.style = `position: absolute; left: ${
        v.positionX * scale
      }px; bottom: ${v.positionY * scale}px;`;
      circle.classList.add("circle");
      circle.setAttribute("data-target", v.id);
      container.appendChild(circle);
    });

    document
      .querySelector(".minimap")
      .addEventListener("click", function (event) {
        const minimap = document.querySelector(".minimap");
        minimap.classList.add("with-transition");
        minimap.classList.toggle("minimap-max");
      });
    document.querySelectorAll(".minimap .circle").forEach((circle) => {
      circle.addEventListener("click", function (event) {
        const target = event.target.getAttribute("data-target");
        event.stopPropagation();
        event.preventDefault();
        if (target === currentTarget) {
          return;
        }
        loadVideo(target);
      });
    });
    onFinish();
  });
}
function buildButton() {
  document.querySelector(".next-button").addEventListener("click", function () {
    const nextVideo =
      tour.videos[
        _.findIndex(
          tour.videos,
          (v) => v.id.toString() === currentTarget.toString()
        ) + 1
      ];
    loadVideo(nextVideo.id.toString());
  });
  document.querySelector(".prev-button").addEventListener("click", function () {
    const prevVideo =
      tour.videos[
        _.findIndex(
          tour.videos,
          (v) => v.id.toString() === currentTarget.toString()
        ) - 1
      ];
    loadVideo(prevVideo.id.toString());
  });

  document.querySelector(".play-button").addEventListener("click", function () {
    const videoSphereEle = document.querySelector("a-videosphere");
    const skyEle = document.querySelector("a-sky");
    videoSphereEle.components.material.material.map.image.play();
    videoSphereEle.object3D.visible = true;
    skyEle.object3D.visible = false;
    document.querySelector("#play-button").classList.add("hidden");
    document.querySelector("#pause-button").classList.remove("hidden");
  });

  document
    .querySelector(".pause-button")
    .addEventListener("click", function () {
      const videoSphereEle = document.querySelector("a-videosphere");
      videoSphereEle.components.material.material.map.image.pause();
      document.querySelector("#pause-button").classList.add("hidden");
      document.querySelector("#play-button").classList.remove("hidden");
    });
}

function buildImageAssets() {
  const assets = document.querySelector("a-assets");
  const splashEle = document.querySelector(".splash-screen");
  tour.videos.forEach((v) => {
    const imageEle = document.createElement("img");
    imageEle.setAttribute("id", `image_${v.id}`);
    imageEle.setAttribute("src", `${v.image}`);
    assets.appendChild(imageEle);
  });
  assets.addEventListener("loaded", function () {
    splashEle.classList.add("hidden");
    console.log("loaded");
  });
}
export default function initVideo() {
  const client = supabase.createClient(
    "https://grwbnxyagpgqazztlpxr.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYxOTc4ODU1MywiZXhwIjoxOTM1MzY0NTUzfQ.CRRgW2N8CB-wE5n4vFlhhuIet8999UqEAsF7beyksKg"
  );
  client
    .from("tour")
    .select("*")
    .match({ slug: "360-video" })
    .then(({ data }) => {
      tour = data[0];
      document.getElementById("scene-root").innerHTML =
        document.getElementById("scene-1").innerHTML;
      document.title = tour.title;
      document.getElementById("desc").setAttribute("content", tour.description);
      const link = document.createElement("link");
      link.rel = "icon";
      link.href = tour.favicon;
      document.getElementsByTagName("head")[0].appendChild(link);
      buildImageAssets();
      buildNav();
      buildMiniMap(function () {
        document.querySelector("a-scene").addEventListener("loaded", () => {
          loadVideo(tour.videos[0].id);
        });
      });
      buildButton();
    })
    .catch((e) => {
      console.log(e);
    });
}

initVideo();
