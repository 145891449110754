AFRAME.registerComponent("zoom-controls", {
  schema: {
    min: { type: "number", default: 20 },
    max: { type: "number", default: 85 },
  },
  init: function () {
    let self = this;
    self.camera = document.querySelector("#camera");
    console.log("min: ", self.data.min);
    console.log("max: ", self.data.max);
    window.addEventListener("wheel", (event) => {
      let amount = Math.sign(event.deltaY) * 2;
      let fov = Number(self.camera.getAttribute("camera").fov);
      let adjust = amount + fov;
      if (adjust < self.data.min) {
        adjust = self.data.min;
      }
      if (adjust > self.data.max) {
        adjust = self.data.max;
      }
      self.camera.setAttribute("camera", "fov", adjust);
    });
  },
});
